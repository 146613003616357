
import {defineComponent} from "vue"

export default defineComponent({
  name: "Cart",
  async beforeMount() {
    const sessionData = await this.$store.dispatch('init')
    console.log('Cart Sdata: ', sessionData)
  },
  methods: {
    test() {
      // this.$store.dispatch('init')
    }
  }
})
